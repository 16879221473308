import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { ApiContextProvider } from '@omnipkg/ui-kit-web';
import * as Sentry from '@sentry/react';

import App from '@src/containers/App';

import { TrackingContextProvider } from './contexts/TrackingContext';

const isDev = process.env.NODE_ENV === 'development';

const baseUrl = isDev
  ? /^https:\/\/delivery-point\.omnillama\.net/
  : /^https:\/\/delivery-point\.dev\.omnillama\.net/;

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: process.env.SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', baseUrl],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <BrowserRouter>
    <ApiContextProvider baseURL={process.env.ECOM_API || ''}>
      <TrackingContextProvider>
        <App />
      </TrackingContextProvider>
    </ApiContextProvider>
  </BrowserRouter>,
);
